<template>
    <user-dashboard-layout>
        <v-row>
            <v-col v-for="plan in pricePlans" :key="plan.id" :cols="4" lg="2" :md="4" :sm="6">
                <v-card>
                    <v-card-title class="text-center" v-text="plan.name"/>
                    <p class="text-center">
                        {{ plan.amount.formatCurrency() }} / <small>{{ plan.interval }}</small>
                    </p>
                    <v-card-actions>
                        <v-btn @click="handlePlanSelect(plan)">Select</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </user-dashboard-layout>
</template>

<script>
    import PricePlan from "../../../models/PricePlan";
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";

    export default {
        name: 'SelectPlan',
        components: { UserDashboardLayout},
        data() {
            return {
                pricePlans: []
            }
        },
        methods: {
            handlePlanSelect(plan) {
                this.$router.push({ name: 'dashboard.billing.checkout', params: { plan } })
            }
        },
        async mounted() {
            this.pricePlans = await PricePlan
                .where('scopes', 'public')
                .get();
        },
    }
</script>

<style scoped>

</style>
